<!-- 个人中心 -->
<template>
  <div id="user">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('person.title')"
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div style="font-size:16px;background:#fff;border-radius:16px 16px 0px 0px;padding:20px 16px">
          <div class="box">
            <van-row>
              <van-col>
                <div
                  class="img-box"
                >
                  <img :src="imageL[5]" style="width:40px; height:40px;" class="img">
                </div>
              </van-col>
              <van-col>
                <div class="text-box">
                  <p>{{ $t('person.account') }} <span> {{ user.accountNum }}</span></p>
                  <p>{{ $t('person.name') }} <span>{{ user.name }}</span></p>
                  <p>{{ $t('person.id') }}<span>{{ user.idserial }}</span></p>
                  <p>{{ $t('person.college') }}<span>{{ user.deptName }}</span></p>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
        <div style="margin-top:10px;" class="svg">
          <van-cell class="red" :title="$t('person.change-password')" :icon="imageL[0]" is-link @click="passwordClick" />
          <!-- <van-cell class="red" :title="$t('person.queryAccountBalance')" :icon="imageL[3]" is-link @click="queryAccountClick" /> -->
          <van-cell class="red" :title="$t('person.unbundling')" :icon="imageL[9]" is-link @click="unbundlingClick" />
          <!-- <van-cell class="blue" :title="$t('person.toast-notifier')" :icon="imageL[1]" is-link /> -->
          <!-- <van-cell class="red" :title="$t('person.invoice-title')" :icon="imageL[2]" is-link @click="invoiceTitleClick" /> -->
          <!-- <van-cell class="yellow" :title="$t('person.request-parcel')" :icon="imageL[3]" is-link @click="toTicket" /> -->
          <!-- <van-cell class="green" :title="$t('person.user-guide')" :icon="imageL[4]" is-link /> -->
          <!-- <van-cell class="green" :title="$t('person.refund-account')" :icon="imageL[6]" is-link @click="refundApplication" /> -->
          <van-cell class="blue" :title="$t('person.fun-bill')" :icon="imageL[7]" is-link @click="more" />
          <van-cell class="yellow" :title="language" :icon="imageL[8]" @click="languageChange" />
        </div>
        <div class="bottom">
          <van-button plain type="info" size="large" class="bottombutton" @click="dealwith">{{ $t('person.log-out') }}</van-button>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
    <ReiTabbar v-if="hackReset" ref="child" />
  </div>
</template>
<script>
import store from '@/store'
import ReiTabbar from '../components/ReiTabbar.vue'
import { findUserInfoByAccountNum } from '@/api/password'

export default {
  name: 'Password',
  components: {
    ReiTabbar
  },
  data() {
    return {
      hackReset: true,
      language: '',
      user: {
        accountNum: '',
        name: '',
        idserial: '',
        deptName: '',
        userId: ''
      },
      imageL: [
        require('../../assets/new_icon/password.png'),
        require('../../assets/new_icon/news.png'),
        require('../../assets/new_icon/ticket-head.png'),
        require('../../assets/new_icon/ticket.png'),
        require('../../assets/new_icon/point.png'),
        require('../../assets/newImages/user.png'),
        require('../../assets/new_icon/refund.png'),
        require('../../assets/new_icon/list.png'),
        require('../../assets/new_icon/language.png'),
        require('../../assets/new_icon/unbundling.png')
      ]
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.init()
    this.languageInit()
    // console.log(this.$store.state.user, 'user')
  },
  methods: {
    //  语言版本初始化
    languageInit() {
      if (window.localStorage.getItem('language') === 'cn') {
        this.language = 'Language switch to English'
      } else {
        this.language = '切换成中文'
      }
    },
    //  语言版本切换
    languageChange() {
      console.log(window.localStorage.language)
      if (window.localStorage.getItem('language') === 'cn') {
        window.localStorage.setItem('language', 'en')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = '切换成中文'
        this.$Local('en')
      } else if (window.localStorage.getItem('language') === 'en') {
        window.localStorage.setItem('language', 'cn')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = 'Language switch to English'
        this.$Local('cn')
      }
      this.hackReset = false
      this.$nextTick(() => {
        this.hackReset = true
      })
    },
    invoiceTitleClick() {
      window.localStorage.setItem('userId', this.user.userId)
      this.$router.push({ path: 'invoiceTitleList' })
    },
    passwordClick() {
      this.$router.push({ path: 'password' })
    },
    queryAccountClick() {
      this.$router.push({ path: 'accountBalance' })
    },
    unbundlingClick() {
      this.$router.push({ path: 'unbundlingCard' })
    },
    refundApplication() {
      this.$router.push({ path: 'refundApplication' })
    },
    more() {
      this.$router.push({ path: 'more' })
    },
    init() {
      const accountNum = localStorage.getItem('accountNum') || this.$store.state.user.accountNum
      const schoolCode = window.localStorage.getItem('schoolcode')
      const dataSource = this.$store.state.settings.dataSource
      findUserInfoByAccountNum(accountNum, schoolCode, dataSource).then(res => {
        this.user.accountNum = res.data.accountNum
        this.user.name = res.data.name
        this.user.idserial = res.data.idserial
        this.user.deptName = res.data.deptName
        this.user.userId = res.data.userId
      })
    },
    toTicket() {
      // this.$router.push({ path: '/progressTracking' })
    },
    // 返回
    onClickLeft() {
      // this.$router.push({ path: '/todo' })
      // this.$router.go(-1)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
      store.dispatch('user/logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>
<style scoped lang="scss">
    #user {
        .van-cell {
            line-height: 30px;
        }

        .van-icon__image {
            width: 40px;
            height: 30px;
        }

        position: relative;
        background-color: #eff6ff;

        .head {
            height: 84px;
            width: 100%;
            background: linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    .outboxstyle {
        // padding: 0px 10px 10px 10px;
        background-color: #F5F5F5;
        border-radius: 10px;
        margin-top: -20px;
        margin-bottom: 100px;
        width: 100%;
        height: 100%;
    }

    .boxstyle {
        margin: 10px 15px 0 15px;
        background-color: #ffffff;
        border: 1px white solid;
        border-radius: 10px;
        box-shadow: 0px 4px 10px #dde9f3;

        p {
            font-size: 16px;
            font-weight: 700;
            margin: 20px;
        }
    }

    .buttontext {
        width: 90px;
        border-radius: 5px
    }

    .bottom {
        width: 100%;
        background: #ffffff;
        height: 80px;
        margin-top: 10px;
    }
    .van-button--plain.van-button--info{
        color: #249FFE;
        border: 1px solid #249FFE;
    }

    .bottombutton {
        margin: 20px 16px;
        width: 91.5%;
        height: 40px;
    }

    .van-cell__value {
        span {
            color: #333333;
        }
    }

    #totalpay {
        .van-cell__value {
            span {
                color: #3BA4F2;
            }
        }
    }
    .svg{
      img{
        width: 30px !important;
        border-radius: 4px;
        margin-right: 10px;
      }
      .red{
        img{
          background-color: #F96662;
        }
      }
      .blue{
        img{
          background-color: #2F9FF2;
        }
      }
      .yellow{
        img{
          background-color: #F89729;
        }
      }
      .green{
        img{
          background-color: #21C69F;
        }
      }
    }
    .van-cell:not(:last-child)::after{
      margin-right: 16px;
    }
</style>
<style>
    .van-nav-bar .van-icon {
        color: #ffffff;
    }

    .van-nav-bar__title {
        color: #ffffff;
    }

    .van-nav-bar__text {
        color: #ffffff;
    }

    [class*='van-hairline']::after {
        border: none;
    }

    .van-nav-bar {
        height: 80px;
    }
</style>
<style lang="scss" scoped>
    .main {
        margin-bottom: 50px;
    }

    .box {
        padding: 0 5%;
        height: 120px;
        background-image: url('../../assets/images/person-back.jpg');
        background-repeat:no-repeat;
        background-size:100% 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
    }
    .van-row{
      display: flex;
      align-items: center;
    }
    .img-box {
        width: 60px;
        height: 60px;
        border: 1px solid #ffffff;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-box {
        margin-left: 20px;
        width: 100%;
        p {
            font-size: 14px;
            height: 20px;
            line-height:20px;
            color: rgba(216, 235, 244, 1);
            span {
                color: #FFFFFF;
            }
        }
    }
</style>
